// application.ts
import { LuminousGallery } from 'luminous-lightbox';

const $ = (id: string) => document.getElementById(id);
const $$ = <E extends Element = HTMLElement>(selector: string) => document.querySelectorAll<E>(selector);

function $e<K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  props: {
    attrs?: Record<string, string>;
    props?: { [A in keyof HTMLElementTagNameMap[K]]?: Partial<HTMLElementTagNameMap[K][A]> };
    style?: Partial<CSSStyleDeclaration>;
  } = {},
  ...children: (string | Node)[]
) {
  const e = document.createElement(tagName);
  Object.assign(e, props.props);
  Object.entries(props.attrs || {}).forEach(([key, value]) => e.setAttribute(key, value));
  Object.assign(e.style, props.style);
  e.append(...children);
  return e;
}

// prevent contextmenu
document.body.addEventListener('contextmenu', (ev) => {
  if ((ev.target as Element).closest('.nocontextmenu')) ev.preventDefault();
});

// lightbox
((elems) => {
  new Set([...elems].map(({ rel }) => rel)).forEach((rel) => {
    const target = $$(`a[rel="${rel}"]`);
    console.log(`Setting up luminous-lightbox (${rel}) target=${target.length}`);
    new LuminousGallery(target, {}, { caption: (el: HTMLAnchorElement) => el.title });
  });
})($$<HTMLAnchorElement>('a[rel^=lightbox]'));

// scroll
document.body.addEventListener('click', (ev) => {
  const a = (ev.target as Element).closest<HTMLAnchorElement>('a[href^="#"]');
  if (!a) return;

  ev.preventDefault();
  const href = a.getAttribute('href')!;
  const target = href === '#top' ? document.body : $(href.slice(1));
  if (!target) {
    console.error(`Cannot find ${href}`);
    return;
  }

  const top = target.getBoundingClientRect().top + window.scrollY;
  window.scrollTo({ top, behavior: 'smooth' });
});

// tablesorter
((elems) => {
  function sortkey(cell?: Element | null) {
    return (cell && ((cell as HTMLElement).dataset.sort || cell?.textContent)) || '';
  }
  elems.forEach((table) => {
    const columns = table.tHead?.querySelectorAll<HTMLTableCellElement>('.sortable');
    if (!columns?.length) return;

    let current: (typeof columnSettings)[number] | null = null;
    let direction: 'ASC' | 'DESC' = 'ASC';
    const columnSettings = [...columns].map((column) => {
      const index = [...column.parentElement!.children].findIndex((child) => child === column);
      const marker = $e('i', { attrs: { class: 'fas fa-sort' } });
      const span = $e('span', { props: { innerHTML: column.innerHTML }, style: { flexGrow: '1' } });
      const cntr = $e(
        'span',
        { style: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5em' } },
        span,
        marker,
      );
      column.replaceChildren(cntr);
      column.style.cursor = 'pointer';
      column.style.userSelect = 'none';
      return { column, marker, index };
    });

    table.tHead!.addEventListener('click', (ev) => {
      const targetColumn = (ev.target as Element).closest<HTMLTableCellElement>('.sortable');
      const target = targetColumn && columnSettings.find(({ column }) => column === targetColumn);
      if (!target) return;

      ev.preventDefault();
      if (current && current !== target) {
        current.column.classList.remove('!bg-red-400');
        current.marker.className = 'fas fa-sort';
        direction = 'ASC';
      } else if (current === target) {
        direction = direction === 'ASC' ? 'DESC' : 'ASC';
      }
      target.column.classList.add('!bg-red-400');
      target.marker.className = `fas fa-sort-${direction === 'ASC' ? 'up' : 'down'}`;
      [...table.tBodies].forEach((tbody) => {
        const rows = [...tbody.children] as HTMLTableRowElement[];
        rows.sort((a, b) => {
          const ak = sortkey(a.children.item(target.index));
          const bk = sortkey(b.children.item(target.index));
          const r = ak > bk ? 1 : ak < bk ? -1 : 0;
          return direction === 'DESC' ? -r : r;
        });
        tbody.replaceChildren(...rows);
      });
      current = target;
    });
    table.tHead!.querySelector('.default-sort')?.dispatchEvent(new Event('click', { bubbles: true, cancelable: true }));
  });
})($$<HTMLTableElement>('.tablesorter'));

// user_kinds
document.body.addEventListener('click', (ev) => {
  const target = ev.target as Element;
  const tr = target.closest('tr');
  const update = () => $$('#user-kinds td:first-child').forEach((elem, i) => (elem.textContent = `${i + 1}`));

  if (target.closest('.moveup-user-kind')) {
    const prev = tr?.previousElementSibling;
    if (prev) tr.parentElement!.insertBefore(tr, prev);
    update();
  } else if (target.closest('.movedn-user-kind')) {
    const next = tr?.nextElementSibling;
    if (next) tr.parentElement!.insertBefore(tr, next.nextElementSibling);
    update();
  } else if (target.closest('.remove-user-kind')) {
    tr?.remove();
    update();
  } else if (target.closest('#add-user-kind')) {
    const name = `NEW${Date.now()}`;
    const html = $('template-user-kind')!.innerHTML.replace(/NEWID/g, name);
    $('user-kinds')!.insertAdjacentHTML('beforeend', html);
    update();
  }
});
